dmx.Component('download', {

  initialData: {
    progress: {
      position: 0,
      total: 0,
      percent: 0,
    },

    lastError: {
      status: 0,
      message: '',
    },
  },

  attributes: {
    timeout: {
      type: Number,
      default: 0,
    },

    url: {
      type: String,
      default: '',
    },

    filename: {
      type: String,
      default: '',
    },
  },

  methods: {
    abort () {
      this._abort();
    },

    download (url) {
      this._download(url);
    },
  },

  events: {
    done: Event,
    error: Event,
    start: Event,
    progress: ProgressEvent,
  },

  init () {
    this._loadHandler = this._loadHandler.bind(this);
    this._abortHandler = this._abortHandler.bind(this);
    this._errorHandler = this._errorHandler.bind(this);
    this._timeoutHandler = this._timeoutHandler.bind(this);
    this._progressHandler = this._progressHandler.bind(this);

    this._xhr = new XMLHttpRequest();
    this._xhr.addEventListener('load', this._loadHandler);
    this._xhr.addEventListener('abort', this._abortHandler);
    this._xhr.addEventListener('error', this._errorHandler);
    this._xhr.addEventListener('timeout', this._timeoutHandler);
    this._xhr.addEventListener('progress', this._progressHandler);
  },

  destroy () {
    this._xhr.removeEventListener('load', this._loadHandler);
    this._xhr.removeEventListener('abort', this._abortHandler);
    this._xhr.removeEventListener('error', this._errorHandler);
    this._xhr.removeEventListener('timeout', this._timeoutHandler);
    this._xhr.removeEventListener('progress', this._progressHandler);
    this._xhr = null;
  },

  _abort () {
    this._xhr.abort();
  },

  _download (url) {
    this._url = url || this.props.url;

    this._xhr.abort();

    this._trigger('start');

    if (this._supportsCors(this._url)) {
      this._xhr.open('GET', this._url);
      this._xhr.responseType = 'blob';
      this._xhr.send();
    } else {
      const a = document.createElement('a');
      a.href = this._url;
      a.download = this.props.filename || this._url.replace(/.*\//, '').replace(/\?.*/, '') || 'download';
      a.rel = 'noopener';
      a.target = '_blank';
      a.dispatchEvent(new MouseEvent('click'));
    }
  },
  
  _supportsCors (url) {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', url, false);
    try { xhr.send(); } catch (e) { }
    return xhr.status >= 200 && xhr.status < 300;
  },

  _trigger (event, data) {
    requestAnimationFrame(() => {
      this.dispatchEvent(event, data);
    });
  },

  _reset () {
    this.set({
      progress: {
        position: 0,
        total: 0,
        percent: 0
      },
      error: {
        status: 0,
        message: '',
        response: null
      }
    });
  },

  _getFilename () {
    const header = this._xhr.getResponseHeader('Content-Disposition');
    const match = header && header.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
    return this.props.filename || (match && match[1] ? match[1].replace(/['"]/g, '') : false) || this._url.replace(/.*\//, '').replace(/\?.*/, '') || 'download';
  },

  _loadHandler () {
    this._reset();
    this._trigger('done');

    if (this._xhr.status >= 200 && this._xhr.status < 300) {
      var blob = this._xhr.response;
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = this._getFilename();
      a.rel = 'noopener';

      setTimeout(() => { URL.revokeObjectURL(a.href); }, 4E4);
      setTimeout(() => { a.dispatchEvent(new MouseEvent('click')); }, 0);
    } else {
      this.set('error', {
        status: this._xhr.status,
        message: this._xhr.statusText
      });
      this._trigger('error');
    }
  },

  _abortHandler () {
    this._reset();
    this._trigger('done');
  },

  _errorHandler () {
    this._reset();
    this.set('error', {
      status: 0,
      message: 'Failed to download'
    });
    this._trigger('error');
    this._trigger('done');
  },

  _timeoutHandler () {
    this._reset();
    this.set('error', {
      status: 0,
      message: 'Download timeout'
    });
    this._trigger('error');
    this._trigger('done');
  },

  _progressHandler (event) {
    var position = event.loaded || event.position;
    var percent = event.lengthComputable ? Math.ceil(event.loaded / event.total * 100) : 0;

    this.set('progress', {
      position: position,
      total: event.total,
      percent: percent
    });

    this._trigger('progress', {
      lengthComputable: event.lengthComputable,
      loaded: position,
      total: event.total
    });
  },

});